<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col cols="12" md="8" class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden">
      <h2>Tambah Daftar Penjualan</h2>
    </b-col>
    <b-col cols="6">
      <ul>
        <li>
          <div class="d-block mb-3">
            <label>Nama Pembeli</label>
            <b-form-input placeholder="Nama Pembeli">
            </b-form-input>
          </div>
        </li>
        <li class="mb-3">
          <div class="d-block mb-3">
            <label>Nomor Handphone</label>
            <b-form-input placeholder="Nomor Handphone" class="mb-3">
            </b-form-input>
          </div>
        </li>
        <li class="mb-3">
          <div class="d-flex flex-column mb-3">
            <label>Golongan Darah</label>
            <b-form-select v-model="selected" :options="options" class="w-25"></b-form-select>
          </div>
        </li>
      </ul>
    </b-col>
    <b-col cols="6">
      <ul>
        <li class="mb-3">
          <label>Jumlah Bloodbags</label>
          <b-form-input placeholder="Masukan jumlah bloodbags" class="mb-3">
          </b-form-input>
        </li>
        <li class="mb-3">
          <label>Harga</label>
          <b-form-input placeholder="Masukan harga" class="mb-3">
          </b-form-input>
        </li>
      </ul>
    </b-col>
    <b-col class="d-flex justify-content-end mb-3">
      <b-button variant="danger" class="mr-3">Batal</b-button>
      <b-button variant="info">Simpan</b-button>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCol,
  BRow,
  BButton,
  BFormInput,
  BFormCheckbox,
  BFormSelect,
} from "bootstrap-vue";

export default {
  name: 'AddBloodvan',
  data() {
    return {
      selected: "A+",
      options: [
        {text: "A+", value: "A+"},
        {text: "A-", value: "A-"},
        {text: "AB", value: "AB"},
        {text: "O", value: "O"},
      ],
    }
  },
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormCheckbox,
    BFormSelect
  }
}
</script>